<template>
  <div>
    <el-row class="flex-right">
      <el-button class="mb" @click="onAdd">新增</el-button>
    </el-row>
    <el-table  :data="tableData" :column="columns" border style="width: 100%">
      <el-table-column type="index" width="50" />
      <el-table-column prop="country" label="country" width="180" />
      <el-table-column prop="name" label="Name" width="180" />
      <el-table-column prop="url" label="url" />
      <el-table-column align="right">
        <template #header>
          <el-input v-model="search" size="small" placeholder="Type to search" />
        </template>
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
            >Edit</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  <div class="demo-pagination-block">
    <div class="demonstration">All combined</div>
    <el-pagination
      v-model:currentPage="page.current"
      v-model:page-size="page.size"
      :page-sizes="[20, 40, 60]"
      :small="page.small"
      :disabled="page.disabled"
      :background="page.background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="30%"
    :before-close="handleClose"
  >
  <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    class="demo-ruleForm"
    :size="formSize"
  >
    <el-form-item label="Activity ID" prop="name">
      <el-input v-model="ruleForm.alexa" />
    </el-form-item>
    <el-form-item label="Activity country" prop="name">
      <el-input v-model="ruleForm.country" />
    </el-form-item>
    <el-form-item label="Activity name" prop="name">
      <el-input v-model="ruleForm.name" />
    </el-form-item>
    <el-form-item label="Activity url" prop="name">
      <el-input v-model="ruleForm.url" />
    </el-form-item>
  </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onSave">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref} from 'vue'
export default defineComponent({
  data() {
    return {
      tableData: [],
      columns: [{
        label: 'url',
        prop: 'url'
      }],
      dialogVisible: false,
      ruleForm: {
        name: '',
        country: '',
        url: '',
        alexa: ''
      },
      rules: {}
    }
  },
  setup() {
    const list = ref([])
    const column =ref( [{
      label: 'url',
      prop: 'url'
    }])
    const page = ref({
      size: 20,
      current: '1',
      total: 0
    })
    const handleSizeChange = (val) => {
      this.page.size = val
      this.getData()
      console.log(val)
    }
    // const handleCurrentChange = (val) => {

    //   console.log(val)
    // }
    return {
      list,
      column,
      handleSizeChange,
      // handleCurrentChange,
      page
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleCurrentChange(val) {
      this.page.current = val
      this.getData()
    },
    onAdd() {
      this.dialogVisible = true
    },
    handleDelete(index, row) {
      this.$jsonPost('/delete', { web: row.id}).then(res => {
        console.log(res)
        this.getData()
      })
    },
    handleEdit(index, row) {
      this.dialogVisible = true
      this.ruleForm = row
    },
    getData() {
      this.$ajaxGet('/one', { size: this.page.size, current: this.page.current }).then(res => {
        this.tableData = res.data
        this.page.size = Number(res.size)
        this.page.current = res.current
        this.page.total = res.total
      })
    },
    onSave() {
      console.log(this.ruleForm)
      let url = '/add'
      let params = { web: [this.ruleForm.name, this.ruleForm.country, this.ruleForm.alexa, this.ruleForm.url]}
      if (this.ruleForm.id ) url = '/edit'
      if (this.ruleForm.id) params = { web: [this.ruleForm.alexa, this.ruleForm.country, this.ruleForm.name, this.ruleForm.url, this.ruleForm.id]}
      this.$jsonPost(url, params).then(res => {
        this.ruleForm.id = ''
        this.dialogVisible = false
        this.getData()
      })
    }
  }
})
</script>